import { z } from "zod";
import { languages, TLanguageCode } from "countries-list";

// Create a type-safe array of language codes
export const validLanguageCodes = Object.keys(languages) as TLanguageCode[];

export const userTypeOptions = [
  { value: "player", label: "Chess Player" },
  { value: "coach", label: "Chess Coach" },
  { value: "organizer", label: "Tournament Organizer" },
  { value: "parent", label: "Parent of Player" },
  { value: "club", label: "Chess Club" },
];

export const discoveryMethodOptions = [
  { value: "search", label: "Search Engine" },
  { value: "social", label: "Social Media" },
  { value: "friend", label: "Friend Recommendation" },
  { value: "tournament", label: "Chess Tournament" },
  { value: "advertisement", label: "Advertisement" },
];

// Type for user type values
type UserType = (typeof userTypeOptions)[number]["value"];
// Type for discovery method values
type DiscoveryMethod = (typeof discoveryMethodOptions)[number]["value"];

export const onboardingSchema = z.object({
  name: z
    .string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be less than 50 characters")
    .trim()
    .refine((val) => /^[a-zA-Z\s\-'.]+$/.test(val), {
      message:
        "Name can only contain letters, spaces, hyphens, apostrophes, and periods",
    }),

  language: z
    .string()
    .refine(
      (val): val is TLanguageCode =>
        validLanguageCodes.includes(val as TLanguageCode),
      {
        message: "Please select a valid language",
      },
    ),

  userType: z.union([
    z.enum(
      userTypeOptions.map((opt) => opt.value) as [UserType, ...UserType[]],
    ),
    z
      .string()
      .min(2, "Custom user type must be at least 2 characters")
      .max(50, "Custom user type must be less than 50 characters")
      .refine(
        (val) =>
          !userTypeOptions.map((opt) => opt.value).includes(val as UserType),
        {
          message:
            "If using a custom value, it must be different from predefined options",
        },
      ),
  ]),

  discoveryMethod: z.union([
    z.enum(
      discoveryMethodOptions.map((opt) => opt.value) as [
        DiscoveryMethod,
        ...DiscoveryMethod[],
      ],
    ),
    z
      .string()
      .min(3, "Custom discovery method must be at least 3 characters")
      .max(100, "Custom discovery method must be less than 100 characters")
      .refine(
        (val) =>
          !discoveryMethodOptions
            .map((opt) => opt.value)
            .includes(val as DiscoveryMethod),
        {
          message:
            "If using a custom value, it must be different from predefined options",
        },
      ),
  ]),
});

export type onboardingValues = z.infer<typeof onboardingSchema>;
