"use client";

import { useRouter, useSearchParams } from "next/navigation";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { signIn, SignInResponse } from "next-auth/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginFormSchema } from "@/lib/validators/account-form-schema";
import { Button, toast } from "@/components/ui";
import AuthSocialButton from "./AuthSocialButton";
import Link from "next/link";
import { Variant } from "@/types/authType";
import { LoginErrorType } from "@/types/LoginErrorType";
import { logger } from "@/lib/logging/logger";
import axios from "axios";
import StyledInput from "./StyledInput";
import { googleSvg } from "./svgs";

const AuthForm = ({
  isModal = false,
  setVariantState,
  redirectTo = "/home",
}: {
  isModal?: boolean;
  setVariantState?: (variant: Variant) => void;
  redirectTo?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginProvider, setLoginProvider] = useState<"email" | "google">(
    "email",
  );
  const router = useRouter();
  const searchParams = useSearchParams();
  const emailParam = searchParams.get("email");
  const error = searchParams.get("error");
  const callbackUrl = searchParams.get("callbackUrl");
  const loginType = searchParams.get("loginType");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FieldValues>({
    defaultValues: {
      email: emailParam,
      password: "",
      ...(loginType && { loginType }),
    },
    resolver: zodResolver(loginFormSchema),
    mode: "onChange",
    criteriaMode: "all",
  });

  const email = watch("email");

  useEffect(() => {
    if (error) {
      const isOAuthError = error === "OAuthAccountNotLinked";
      toast({
        description: isOAuthError
          ? "The email on this account is already used with another sign-in method. Please sign in with your original sign-in method (email/password)."
          : error,
        duration: isOAuthError ? 6000 : 4000,
        variant: "destructive",
      });
    }
  }, [error]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setIsLoading(true);
    setLoginProvider("email");

    const finalRedirectUrl =
      callbackUrl && !callbackUrl.includes("/register")
        ? callbackUrl
        : redirectTo;

    signIn("credentials", {
      ...data,
      redirect: false,
    })
      .then((callback) =>
        handleSignInCallback(callback, data, finalRedirectUrl),
      )
      .finally(() => setIsLoading(false));
  };

  const handleSendVerificationEmail = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      return await axios.post("/api/verify-email/send_verification", {
        email: email,
        password: password,
      });
    },
    [],
  );

  const handleSignInCallback = (
    callback: SignInResponse | undefined,
    data: FieldValues,
    finalRedirectUrl: string,
  ) => {
    if (callback?.error) {
      if (callback?.error === LoginErrorType.EMAIL_NOT_VERIFIED) {
        const toastError = toast({
          description: (
            <div>
              {callback?.error}
              <br />
              <button
                onClick={async () => {
                  try {
                    await handleSendVerificationEmail({
                      email: data.email,
                      password: data.password,
                    });
                    toastError.dismiss();
                    toast({
                      description: "Verification email sent!",
                      duration: 3000,
                    });
                  } catch (err: any) {
                    logger.info(err);
                    toast({
                      description:
                        `${err.response.data.error}` ||
                        "Could not send mail. Please try again later.",
                      duration: 3000,
                      variant: "destructive",
                    });
                  }
                }}
                className="text-slate-700 underline hover:text-slate-400"
              >
                Send verification email
              </button>
            </div>
          ),
          duration: 5000,
        });
      } else if (callback?.error === LoginErrorType.INVALID_CREDENTIALS) {
        toast({
          description:
            "Your credentials are invalid or you have not verified your email yet.",
          duration: 3000,
          variant: "destructive",
        });
      } else if (
        callback?.error === LoginErrorType.WRONG_PROVIDER &&
        callback?.ok
      ) {
        router.push(finalRedirectUrl);
        router.refresh();
      }
    } else if (callback?.ok) {
      router.push(finalRedirectUrl);
      router.refresh();
    }
  };

  const socialAction = (action: string) => {
    setIsLoading(true);
    setLoginProvider("google");
    signIn(action, { redirect: false })
      .then((callback) => {
        if (callback?.error) {
          toast({
            description: "Invalid credentials!",
            duration: 3000,
            variant: "destructive",
          });
        } else if (callback?.ok) {
          router.push(redirectTo);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const renderAdminMode = () => (
    <div className="flex flex-col gap-2">
      <p className="text-red-500 text-center font-bold">Admin Mode Login!</p>
      <p className="text-sm text-red-500 text-center">
        Enter your credentials again in order to enter admin mode
      </p>
    </div>
  );

  const renderForm = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="self-stretch h-max flex-col justify-start items-center gap-8 flex"
      >
        {loginType === "adminMode" && renderAdminMode()}
        <div className="self-stretch h-max flex-col justify-start items-start gap-6 flex">
          <StyledInput
            disabled={isLoading}
            register={register}
            errors={errors}
            required
            id="email"
            label="Email address"
            errorsShow
          />
          <StyledInput
            disabled={isLoading}
            register={register}
            errors={errors}
            required
            type="password"
            id="password"
            label="Password"
            errorsShow
          />
        </div>
        <div className="self-stretch h-max flex-col justify-start items-center gap-4 flex">
          <Button
            disabled={isLoading}
            type="submit"
            className="hover:bg-blue-600 transition-colors text-white text-sm font-semibold leading-tight self-stretch h-[42px] px-4 py-2 bg-brand-secondary rounded-lg border border-white justify-center items-center gap-1 inline-flex"
          >
            {isLoading && loginProvider === "email" ? "Logging in" : "Log in"}
          </Button>
          <AuthSocialButton
            icon={googleSvg()}
            disabled={isLoading}
            onClick={() => socialAction("google")}
            provider="Google"
            action={
              isLoading && loginProvider === "google" ? "Logging in" : "Log in"
            }
          />
        </div>
      </form>
    );
  };

  const renderFormActions = () => {
    return (
      <div className="self-stretch h-max flex-col justify-start items-center gap-2.5 flex">
        <div className="flex justify-center gap-1 self-stretch text-center">
          <span className="text-slate-600 text-base font-normal  leading-normal">
            New to xChess.ai?{" "}
          </span>
          {!isModal && (
            <Link
              prefetch={false}
              href={`/register${email ? `?email=${encodeURIComponent(email)}` : ""}`}
              className="cursor-pointer text-brand-secondary text-base font-normal  underline leading-normal"
            >
              Register
            </Link>
          )}
          {isModal && setVariantState && (
            <div
              className="cursor-pointer text-brand-secondary text-base font-normal  underline leading-normal"
              onClick={() => setVariantState("REGISTER")}
            >
              Register
            </div>
          )}
        </div>
        <div className="flex justify-center gap-1 self-stretch text-center">
          <span className=" text-slate-600 text-base font-normal  leading-normal">
            Forgot your password?{" "}
          </span>
          {!isModal && (
            <Link
              prefetch={false}
              href={`/reset${email ? `?email=${encodeURIComponent(email)}` : ""}`}
              className="text-brand-secondary text-base font-normal  underline leading-normal"
            >
              Reset it here
            </Link>
          )}
          {isModal && setVariantState && (
            <div
              className="cursor-pointer text-brand-secondary text-base font-normal  underline leading-normal"
              onClick={() => setVariantState("FORGOT_PASSWORD")}
            >
              Reset it here
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {renderForm()}
      {renderFormActions()}
    </Fragment>
  );
};

export default AuthForm;
