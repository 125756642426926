import {
  passwordSchema,
  passwordUnionAccDetails,
} from "@/types/change-password-form-type";
import { TLanguageCode } from "countries-list";
import { z } from "zod";
import { validLanguageCodes } from "./onboarding-schema";

export const userAccountFormSchema = passwordUnionAccDetails.extend({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
  email: z
    .string({
      required_error: "Please select an email to display.",
    })
    .email(),
  showMovePopup: z.boolean().default(false),
  language: z
    .string()
    .refine(
      (val): val is TLanguageCode =>
        validLanguageCodes.includes(val as TLanguageCode),
      {
        message: "Please select a valid language",
      },
    ),
});

export type UserAccountFormValues = z.infer<typeof userAccountFormSchema>;

export const adminUserAccountFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
  email: z
    .string({
      required_error: "Please select an email to display.",
    })
    .email(),

  role: z.string().optional(),
});

export type AdminUserAccountFormValues = z.infer<
  typeof adminUserAccountFormSchema
>;

export const signUpFormSchema = z
  .object({
    name: z
      .string()
      .min(2, {
        message: "Name must be at least 2 characters.",
      })
      .max(30, {
        message: "Name must not be longer than 30 characters.",
      })
      .optional(),
    email: z
      .string({
        required_error: "Please select an email to display.",
      })
      .email(),
    password: passwordSchema,
    confirmPassword: z
      .string()
      .min(1, { message: "Confirm password is required." }),
    refId: z
      .string()
      .length(25, { message: "Must be exactly 25 characters long." })
      .optional(),
    promoId: z.string().optional(),
    redirectTo: z.string().optional(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match.",
    path: ["confirmPassword"],
  });

export const loginFormSchema = z.object({
  email: z
    .string({
      required_error: "Email field is required.",
    })
    .email()
    .min(1, { message: "Email field is required." }),
  password: z
    .string({
      required_error: "Password field is required.",
    })
    .min(1, { message: "Password field is required." }),
  loginType: z.string().optional(),
});

export type LoginFormValues = z.infer<typeof loginFormSchema>;

export type SignUpFormValues = z.infer<typeof signUpFormSchema>;
